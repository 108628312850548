/** @jsx jsx */
import { jsx } from "theme-ui";
import Content from "@src/templates/content";
import ImageTextBlock from "@src/components/ImageTextBlock";
import Testimonial from "@src/components/Testimonial";
import Container from "@src/components/Container";
import CTABanner from "@src/components/CTABanner";
import InternalLink from "@src/components/InternalLink";
import { Link } from "gatsby";
export default ({ location }) => (
  <Content pageTitle="Comics Performance" location={location}>
    <div>
      <Container>
        <div>
          <ImageTextBlock
            alt="Bernard sitting on table in front of sketches with hands up"
            src="/images/bernard-kamishibai.jpg"
            body={
              <div>
                <p>
                  Watch comics come to life as Bernard performs tales of
                  suspense, surprises and laughs.
                </p>
                <h2>Kamishibai</h2>

                <p>
                  Bernard performs kamishibai (“paper theatre”), a form of live
                  comics street theatre that emerged in Japan in the 1930s.
                  Kamishibai led to the boom in Japanese comics and animation
                  which continues today.
                </p>

                <p>
                  Bernard performs kamishibai stories and talks about this
                  unique artform in the context of Japanese history and how it
                  became part of the evolution of Japanese comics and animation.
                </p>

                <p>
                  Kamishibai is particularly popular with primary school
                  students, who love to see what the next picture will be and to
                  interact with the theatrical storytelling style.
                </p>
              </div>
            }
          />
        </div>
      </Container>

      <Testimonial
        text="For our Elementary students, Bernard introduced the world of Kamishibai, a form of
        Japanese storytelling. But Bernard did not just tell Kamishibai stories. Bernard performed
        the stories, gave life to the stories and inspired our students to try to imitate his passion for
        stories. His efforts were most effective because he used Kamishibai as the means for the
        students to understand the intricacies of this Japanese art form. It was through Bernard’s
        Kamishibai stories that we learned of the history of this ‘paper theater’ in Japan beginning
        in the 1930’s. Bernard’s energy and enthusiasm, to present stories and to observe stories
        created by our students, was limitless. He was a crowd favourite for all ages and well
        appreciated by our teachers for the clear way he could communicate how a Kamishibai
        story works."
        author="Anne Coster and Sally Bray, <br/> International School of Phnom Penh"
      />

      <CTABanner
        lines={[
          <div>
            <Link to="/contact">Contact Booked Out</Link> to book a kamishibai
            session
          </div>,
          <div>
            <a
              href="https://www.facebook.com/readingsbooks/videos/1586549814778459"
              target="_blank"
            >
              Watch Bernard perform kamishibai
            </a>{" "}
            to an enthralled bookshop audience.
          </div>,
        ]}
      />

      <Container>
        <InternalLink text="Comics teaching" url="/comics-teaching" />
      </Container>
    </div>
  </Content>
);
